import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import backArrow from '../../assets/back.svg';
import Pages from '../../animation/Pages';
import { useLocation } from 'react-router-dom';
import { UPDATE_HOME_PAGE, UPDATE_ABOUT_PAGE } from '../../api/mutations';
import { UPDATE_HOME_PAGE_2 } from '../../api/brochure2/mutations';
import { useMutation } from '@apollo/client';
import Loading from '../Loading';
import { ApolloClient, InMemoryCache } from "@apollo/client";
import {createUploadLink } from "apollo-upload-client";
import { useNavigate } from 'react-router-dom';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { useEffect } from 'react';
import { GET_HOME_DATA } from '../../api/GraphQL';
import { useQuery } from '@apollo/client';

import { v4 as uuidv4 } from 'uuid';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';


//uploading new images to cloud storage
const S3_ENDPOINT = 'https://aqualona-img-file.ams3.digitaloceanspaces.com';
const S3_ACCESS_KEY = process.env.REACT_APP_DIGITAL_OCEAN_ACCESS_KEY_2; 
const S3_SECRET_KEY = process.env.REACT_APP_SPACE_SECRET; 
const S3_BUCKET_NAME = 'aqualona-img-file'; 


if (process.env.NODE_ENV !== "production") {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export default function Edit({page}) {
  const { slug } = useParams();
  //home page content - ONLY



  const [contentData, setContentData ] = useState(null)
  
  const navigate = useNavigate();

  const location = useLocation();
  const  {content, property, type}  = location.state;
  const pathnameParts = location.pathname.split('/');
  const secondToLastPart = pathnameParts[pathnameParts.length - 3];
//   console.log(secondToLastPart);
// console.log(type, 'this is the type')
  useEffect(() => {
    if (content) {
      setContentData(content);
    }
  }, [content]);

  const [ updateHome, { errorUpdate } ] = useMutation(UPDATE_HOME_PAGE);
  const [updateHome2, {errorUpdate2}] = useMutation(UPDATE_HOME_PAGE_2);
const [updateAbout, {errorUpdateAbout}] =useMutation(UPDATE_ABOUT_PAGE);

  //variable to be used as mutation variable
  const [newContent, setNewContent] = useState(content);

  const [success, setSuccess] = useState(false);


  //SDK SETUP
  const s3Client = new S3Client({
    endpoint: S3_ENDPOINT,
    forcePathStyle: true,
    region: 'ams3',
    credentials: {
      accessKeyId: S3_ACCESS_KEY,
      secretAccessKey: S3_SECRET_KEY,
    },
  });

  const uploadImageToS3 = async (file) => {
    if (!file) {
      console.error('No file to upload.');
      return null;
    }

    const uniqueKey = `${uuidv4()}-${file.name}`;

    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: uniqueKey,
      Body: file,
      ACL: 'public-read',
      ContentType: 'image',
      ContentDisposition: 'inline',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const endpoint = `${S3_ENDPOINT}/${S3_BUCKET_NAME}/${uniqueKey}`;
      // console.log(endpoint);
      return endpoint;

    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };


  // Function to handle file input change
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadImageToS3(file);
      if (imageUrl) {
        setNewContent((prevContent) => ({
          ...prevContent,
          [slug]: imageUrl, // Set the value of the specific property based on the slug
        }));
        // console.log(slug, "slug");
        // console.log(newContent[slug], "new content");
        // console.log(imageUrl, "imageURL");
      }
    }
  };

  const handleUpdatedContent = (e) => {
    const updatedValue = e.target.value;
    const updatedContent = {
      ...newContent,
      [slug]: updatedValue,
    };
    setNewContent(updatedContent);
    console.log(updatedContent, "updated content");
  };
  
  
  const handleClick = async (e) => {
    e.preventDefault();

    if(secondToLastPart === 'home'){
      if(type === 'brochure1'){
        // console.log('this is brochure ONE', type);
        try {
            const { data, loading, error } = await updateHome({
                variables: {
                    data: {
                    pageHeader: newContent.pageHeader, 
                    subHeader: newContent.subHeader,
                    button: newContent.button,
                    description: newContent.description,
                    coverImageURL: newContent.coverImageURL
                    },
                },
                update: (cache, {data: { updateHome}}) => {
                  cache.modify({
                    fields: {
                      home(existingHome ={}) {
                        return {
                          ...existingHome,
                          data: updateHome,
                        }
                      }
                    }
                  })
                }
            });

            if(loading){
                return <Loading />
            }
            if(error) {
                console.error('not updated');
            }
            if(data){
                
                  navigate(-1); // 
                 
                 setSuccess(true);
            }
            
        }
        catch {
            // console.error("Data error", error);
        }
      }
      if(type === 'brochure2'){
        // console.log('this is brochure two', type)
        try {
            const { data, loading, error } = await updateHome2({
                variables: {
                    data: {
                    pageHeader: newContent.pageHeader, 
                    subHeader: newContent.subHeader,
                    button: newContent.button,
                    description: newContent.description,
                    coverImageURL: newContent.coverImageURL
                    },
                },
                update: (cache, {data: { updateHome2}}) => {
                  cache.modify({
                    fields: {
                      homeTwo(existingHome ={}) {
                        return {
                          ...existingHome,
                          data: updateHome,
                        }
                      }
                    }
                  })
                }
            });

            if(loading){
                return <Loading />
            }
            if(error) {
                console.error('not updated');
            }
            if(data){
                
                 navigate(-1); // instead of this please go back to previous page
                
                setSuccess(true);
            }
            
        }
        catch {
            // console.error("Data error", error);
        }
      }
    }
    if(secondToLastPart === 'about'){
        try {
            const { data, loading, error } = await updateAbout({
                variables: {
                    data: {
                    pageHeader: newContent.pageHeader, 
                    paragraph1: newContent.Paragraph1,
                    paragraph2: newContent.paragrah2,
                    slogan: newContent.slogan,
                    image1URL: newContent.image1URL,
                    image2URL: newContent.image2URL,
                    landscapeImageURL: newContent.landscapeImageURL,
                    },
                },
                update: (cache, {data: { updateAbout}}) => {
                  console.log('Update About data:', updateAbout);
                  cache.modify({
                    fields: {
                      aboutUs(existingAbout ={}) {
                        return {
                          ...existingAbout,
                          data: updateAbout,
                        }
                      }
                    }
                  })
                }
            });
            // console.log('Mutation Data:', data);

            if(loading){
                return <Loading />
            }
            if(error) {
                console.error('not updated');
            }
            if(data){
              setSuccess(true);
              //  navigate(-1); // instead of this please go back to previous page
              setTimeout(() => {
                  navigate(-1);
                  
                }, 1000);
                 
            }
            
        }
        catch {
            // console.error("Data error", error);
        }
    }

  }

  if(!content) {
    return <Loading />
  }
  // console.log(contentData, "content data")

  return (
    <Pages>
    <div className='absolute left-0 bg-bgCol top-0 w-full h-full'>
      <div className='border-b-[0.5px] border-line p-4'>
        <Link className='flex space-x-2' to='..'>
          <img src={backArrow} alt='back arrow' />
          <span>Back to Home Page</span>
        </Link>
      </div>
      <div className='p-20 space-y-10'>
        {property === 'Cover Image' || type ==='img' ? (
          <div className='flex space-x-20'>
            <span>{property}</span>
            <label className='relative w-60 h-10 bg-gray-300 border-r-[0.5px] border-b-[0.5px] border-black rounded-md'>
              <input
                type='file'
                className='absolute w-full h-full opacity-0 cursor-pointer'
                onChange={handleImageChange}
                accept="image/jpeg, image/png, image/gif, image/webp" //accept only image files with these extensions
              />
        {newContent && newContent[slug] ? (
          <img
            className='cursor-pointer hover:shadow-xl transition duration-300'
            src={newContent[slug]}
            alt='to edit'
          />
              ) : (
                <span className='cursor-pointer absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500'>
                  Choose File
                </span>
              )}
            </label>
          </div>
        ) : (
          <>
            <div className='text-7xl text-[#C5BEB1]'>Edit Text</div>
            <div className='flex space-x-20'>
              <span>{property}</span>
              <textarea
                type='textbox'
                value={newContent && newContent[slug]}
                onChange={handleUpdatedContent}
                placeholder={contentData && contentData[slug]}
                className='rounded-none w-60 focus:outline-none bg-[#E6E4E0] border-r-[0.5px] border-b-[0.5px] border-black'
              />
            </div>
          </>
        )}
      </div>
      <div className='absolute bottom-0 right-0 w-full border-t-[0.5px] border-line'>
        <div className='float-right bg-black px-20 py-10'>
          <button onClick={handleClick} className='text-white uppercase w-auto'>
            {success ? "Saved!" : "Save"}
          </button>
        </div>
      </div>
    </div>
  </Pages>
  );
}

import React from 'react';
import Options from '../components/Options';
import { Outlet } from 'react-router-dom';
import Loading from '../components/Loading';
import { useQuery } from '@apollo/client';
import { GET_ABOUT_US_DATA } from '../api/GraphQL';
import Pages from '../animation/Pages';


const About = () => {
  const { loading, error, data } = useQuery(GET_ABOUT_US_DATA);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const { paragraph1, paragraph2, slogan, image1URL, image2URL, landscapeImageURL, pageHeader } = data.aboutUs.data.attributes;

  

  const content = {paragraph1, paragraph2, slogan, image1URL, image2URL, landscapeImageURL, pageHeader};

  return (
    <Pages>
      <div className='p-20 pr-10 w-[100%] overflow-y-auto'>
      <div className='w-full h-auto pr-10 overflow-y-auto scroll-smooth'>
                <h1 className='text-[#C5BEB1] text-6xl font-semibold'>Home</h1>
                <ol className='space-y-8 py-10 w-full'>

                    {/* page header */}
                    <li className=' flex justify-between font-light'>
                        <span className='font-semibold pr-8 w-1/5'>Page Header</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{pageHeader}</div>
                            <Options label='Edit Text' link='edit/pageHeader' content={content} contentLabel='Page Header' initialDropdownVisible={false} />
                        </div>

    

                    </li>

                    {/* Paragraph 1 */}
                    <li className=' flex justify-between font-light'>
                        <span className='font-semibold pr-8 w-1/5'>Paragraph 1</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{paragraph1}</div>
                            <Options label='Edit Text' link='edit/paragraph1' content={content}  contentLabel='Paragraph 1' initialDropdownVisible={false} />
                        </div>

                       

                    </li>

                    {/* image 1-1  */}
                    <li className=' flex justify-between font-light'>
                        <span className='font-semibold pr-8 w-1/5'>Image 1-1</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>
                                <img src={image1URL} alt="example" className="max-h-60 object-fit"/>
                            </div>
                            <Options label='Replace Image' link='edit/image1URL' type='img' content={content}  contentLabel='Image 1-1' initialDropdownVisible={false} />
                        </div>
                    </li>

                    {/* image 1-2  */}
                    <li className=' flex justify-between font-light border-dashed border-b-[0.5px] border-black pb-8'>
                        <span className='font-semibold pr-8 w-1/5'>Image 1-2</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>
                                <img src={image2URL} alt="example" className="max-h-60 object-fit"/>
                            </div>
                            <Options label='Replace Image' link='edit/image2URL' type='img' content={content}  contentLabel='Image 1-2' initialDropdownVisible={false} />
                        </div>
                    </li>
 

                    {/* Paragraph 2 */}
                    <li className=' flex justify-between font-light '>
                        <span className='font-semibold pr-8 w-1/5'>Paragraph 2</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{paragraph2}</div>
                            <Options label='Edit Text' type='img' link='edit/paragraph2' content={content}  contentLabel='Paragraph 2' initialDropdownVisible={false} />
                        </div>
                    </li>

                    {/* description */}
                    <li className=' flex justify-between font-light'>
                        <span className='font-semibold pr-8 w-1/5'>Slogan</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{slogan}</div>
                            <Options label='Edit Text' link='edit/slogan' content={content}  contentLabel='Slogan-style Content' initialDropdownVisible={false} />
                        </div>
                    </li>

                    {/* landscape image */}
                    <li className=' flex justify-between font-light border-dashed border-b-[0.5px] border-black pb-8'>
                        <span className='font-semibold pr-8 w-1/5'>Landscape Image</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>
                                <img src={landscapeImageURL} alt="example" className="max-h-60 object-fit"/>
                            </div>
                            <Options label='Replace Image' link='edit/landscapeImageURL' type='img' content={content}  contentLabel='Image 1-2' initialDropdownVisible={false} />
                        </div>
                    </li>

                </ol>
            </div>
        <Outlet />
      </div>
    </Pages>
  );
};

export default About;

import React, { useState } from 'react';

export default function HomeMenu({ onBrochureClick }) {
  const [activeBrochure, setActiveBrochure] = useState(false);

  const handleBrochure1Click = () => {
    // When Brochure 1 button is clicked, set activeBrochure to false
    setActiveBrochure(false);
    onBrochureClick(false);
  };

  const handleBrochure2Click = () => {
    // When Brochure 2 button is clicked, set activeBrochure to true
    setActiveBrochure(true);
    onBrochureClick(true);
  };

  return (
    <div className="w-full h-full pt-8 pl-2 border-r-[0.5px] border-brown flex flex-col space-y-20">
      <h2 className="font-bold p-2 w-full border-r-2 border-black bg-[#DCD9D3]">Brochures</h2>
      <ul className="list-disc text-sm pl-6 mx-auto space-y-12 w-full list-inside">
        <li className={activeBrochure ? 'p-4' : 'p-4 font-bold bg-[#DCD9D3] w-full transition duration-300'}>
          <button onClick={handleBrochure1Click}>Brochure 1</button>
        </li>
        <li className={activeBrochure ? 'p-4 font-bold bg-[#DCD9D3] w-full transition duration-300' : 'p-4 '}>
          <button onClick={handleBrochure2Click}>Brochure 2</button>
        </li>
      </ul>
    </div>
  );
}

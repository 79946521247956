import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DELETE_PRODUCT } from '../../api/mutations';
import { DELETE_PRODUCT_2 } from '../../api/brochure2/mutations';
import {  useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_PRODUCTS_BY_CATEGORY } from '../../api/GraphQL';
import { GET_PRODUCTS_BY_CATEGORY_2 } from '../../api/brochure2/GraphQL';
import { GET_PRODUCT_CATEGORY_BY_ID } from '../../api/GraphQL';
import { GET_PRODUCT_CATEGORY_BY_ID_2 } from '../../api/brochure2/GraphQL';



export default function Delete() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const location = useLocation();
  const { content } = location.state;
  console.log(content, 'state content')

  const category_ID = content.product_category;
  const isProduct2 = location.pathname.includes('products-2');
  const selectedRefreshQuery = isProduct2 ? GET_PRODUCTS_BY_CATEGORY_2 : GET_PRODUCTS_BY_CATEGORY;
  const selectedMutation = isProduct2 ? DELETE_PRODUCT_2 : DELETE_PRODUCT;
  const selectedQuery = isProduct2 ? GET_PRODUCT_CATEGORY_BY_ID_2 : GET_PRODUCT_CATEGORY_BY_ID;
console.log(slug, ':product ID')
  const [deleteProduct] = useMutation(selectedMutation);

  



  const handleDeleteProduct = async () => {

    try {
      await deleteProduct({
        variables: { id: slug },
        refetchQueries: [{ query: selectedRefreshQuery, variables: { id: category_ID } }],
      });
      console.log('Product deleted');
      navigate(-1);
    } catch (error) {
      console.error('Error deleting product', error);
    }
  };

  const returnToProducts = () => navigate(-1);

  return (
    <div className='z-10 w-full h-full left-0 absolute top-0 bg-black bg-opacity-60 flex'>
      <div className='bg-bgCol h-auto m-auto p-20 rounded-md flex flex-col'>
       
        <h4 className='font-bold'>Are you sure you want to delete the product?</h4>
        <div className='p-10 flex mx-auto space-x-4'>
          <button className='bg-black p-2 px-8 text-bgCol w-full' onClick={handleDeleteProduct}>Yes</button>
          <button className='border-2 border-black p-2 px-8 w-full' onClick={returnToProducts}>Cancel</button>
        </div>
      </div>
    </div>
  );
}


import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

export default function CategoryNav({ categories }) {
  const { pathname } = useLocation();

  return (
    <div className="w-full h-full pt-8 pl-2 border-r-[0.5px] border-brown flex flex-col space-y-20">
      <h2 className="font-bold p-2 w-full border-r-2 border-black bg-[#DCD9D3]">Our Products</h2>
      <ul className="list-disc text-sm pl-6 mx-auto space-y-12 pr-2">
        {categories.map((category) => {
          const encodedTitle = encodeURIComponent(category.attributes.title);
          const categoryPath = `/products/${encodedTitle}`;
          const isActive = pathname.endsWith(categoryPath);

          return (
            <AnimatePresence key={`brochure_._1_${category.id}`}>
            <li key={`brochure_1_${category.id}`} className={`relative hover:underline ${isActive ? '' : ''}`}>
              <Link to={categoryPath} state={{ categoryId: category.id }}>
              {isActive && (
                <motion.span
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                exit={{ width: 0 }}
                className='absolute left-0 bottom-0 block h-[0.5px] w-full bg-[#9f9080]'
              />
            )}
                {category.attributes.title}
              </Link>
            </li>
            </AnimatePresence>
          );
          
        })}
      </ul>
    </div>
  );
}

//Cookie managment - JWT, user data

import Cookies from 'js-cookie';
import { fetcher } from './api';

export const setToken = (data) => {

  if(typeof window === 'undefined'){
    return;
  }

  Cookies.set('id', data.user.email);
  Cookies.set('username', data.user.username);
  Cookies.set('jwt', data.jwt);
  
  if (Cookies.get('username')) { 
    
    // reaload window
    window.location.reload(false);
    
  }
};

//remove token whe user logs out
export const unsetToken = () => {


  if (typeof window === 'undefined') {
    return;
  }

  Cookies.remove('id');
  Cookies.remove('username');
  Cookies.remove('jwt');
  


};

export const getUserFromCookie = () => {
  const jwt = getTokenFromCookie();
  if (jwt) {
    return fetcher(`${process.env.REACT_APP_STRAPI_URL}/api/users/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then((data) => {
        return data.username;
      })
      .catch((error) => console.error(error));
  } else {
    return;
  }
};



export const getIdFromCookie = () => {
  return Cookies.get('id');
};

export const getTokenFromCookie = () => {
  return Cookies.get('jwt');
};


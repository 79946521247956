import React from 'react';
import { GET_ALL_CATEGORIES_2, GET_CATEGORY_PAGE_DATA_2 } from '../../../api/brochure2/GraphQL';
import Loading from '../../Loading';
import { useQuery } from '@apollo/client';
import Options from '../../Options';
import DraggableList from '../products/DraggableList';
import { Outlet, useLocation } from 'react-router-dom';
import { client } from "../../../api/Apollo"
import { UPDATE_CATEGORY_ORDER_2 } from '../../../api/brochure2/mutations';
import { useMutation } from '@apollo/client';

export default function Categories2() {
  const location = useLocation();
  const contentFromState = location.state?.newContent;
  const { loading, error, data } = useQuery(GET_ALL_CATEGORIES_2);
  const { loading: loadingCategory, error: errorCategory, data: dataCategory } = useQuery(GET_CATEGORY_PAGE_DATA_2);
  const [updateOrder] = useMutation(UPDATE_CATEGORY_ORDER_2)
  

  if (loading || loadingCategory) {
    return <Loading />;
  }

  if (error || errorCategory) {
    return <p>Data Error</p>;
  }
  
  // console.log(contentFromState)
  const { pageHeader, coverImageURL, paragraph } = contentFromState || dataCategory.categoryTwo.data.attributes;
  const categories = data.product2Categories.data;

  const onReorder = async (newCategories) => {
    console.log(newCategories, 'new categories');
  
    // Update each category's order on the server
    const updatePromises = newCategories.map((category) => 
      updateOrder({
        variables: {
          id: category.id, // Assuming each category has an ID
          order: category.order // Directly use the order property from newCategories
        },
      }).catch(error => {
        console.error(error, 'update failed for category:', category.id);
        return null; // Return a marker or null to indicate failure
      })
    );
  
    await Promise.all(updatePromises);
  
    // After successfully updating the server, manually update the cache
    client.cache.modify({
      fields: {
        product2Categories(existingCategories = {}, { readField }) {
          // Create a new ordered array of category references
          const newCategoryData = newCategories.map((category) => {
            const ref = existingCategories.data.find(ref => readField('id', ref) === category.id);
            return {
              ...ref,
              attributes: {
                ...ref.attributes,
                order: category.order,
              },
            };
          });
          // Update the cache with the new ordered data
          return {
            ...existingCategories,
            data: newCategoryData,
          };
        },
      },
    });
  
    console.log('Cache updated with new category order');
  };

  return (
    <div className='space-y-8 w-full h-full overflow-y-auto'>
      <h1 className='text-5xl text-line font-bold'>Categories</h1>
      <ul className='space-y-8'>
        <li className='flex w-full justify-between'>
          <h3 className='w-1/5'>Page Header</h3>
          <span className='w-3/5 font-light text-sm'>{pageHeader}</span>
          <Options label='Edit Text' content={contentFromState || { pageHeader, coverImageURL, paragraph }} type="Page Header" link='edit/pageHeader' />
        </li>
        <li className="flex w-full justify-between">
          <h3 className="w-1/5">Paragraph 1</h3>
          <span className="w-3/5 font-light text-sm">{paragraph}</span>
          <Options label='Edit Text' link='edit/paragraph' type="Paragraph" content={contentFromState || { pageHeader, coverImageURL, paragraph }} />
        </li>
        <li className="flex w-full justify-between">
          <h3 className='w-1/5'>Cover Image</h3>
          <img className='w-3/5' src={coverImageURL} alt='cover image'/>
          <Options label='Replace Image' link='edit/coverImageURL' type="Cover Image" content={contentFromState || { pageHeader, coverImageURL, paragraph }} />
        </li>
      </ul>

      <h3 className="text-[#C5BEB1] pt-20 py-6">Categories ({categories.length})</h3>

      <div className=''>
        <DraggableList className='' property='Title' categories={categories} onReorder={onReorder} />
      </div>
      <Outlet />
    </div>
  );
}

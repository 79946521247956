import logo from '../assets/Logo.svg';
import background from '../assets/login_bg.svg'
import React, { useState } from 'react';

import { useMutation, } from '@apollo/client'; // Import useApolloClient
import { setToken } from '../lib/auth';
import { unsetToken } from '../lib/auth';
import { LOGIN_MUTATION } from '../api/GraphQL';

export default function Login({user, loading}) {

    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [loginFailed, setLoginFailed] = useState(false);

    const [loginErrorMessage, setLoginErrorMessage] = useState('');
    // const [loginMutation, { error }] = useMutation(LOGIN_MUTATION);
    const [loginMutation] = useMutation(LOGIN_MUTATION);

    // useEffect(() => {
    //   if (error) {
    //     setLoginFailed(true);
    //   } else {
    //     setLoginFailed(false);
    //   }
    // }, [error]);
    const handleLogin = async (e) => {
      e.preventDefault();

      unsetToken();
      try {
        const { data } = await loginMutation({
          variables: { identifier, password },
        });
    
        if (data && data.login) {
          setToken(data.login);
          // Redirect or perform necessary action for successful login
        } else {
          // Set login failure state as login was not successful
          setLoginFailed(true);
          setLoginErrorMessage('An error occurred. Please check your credentials.');
        }
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          // Extract the first error message for simplicity
          const errorMessage = error.graphQLErrors[0]?.message || 'An error occurred';
          
          // Set login failure state and display specific error message
          setLoginFailed(true);
          setLoginErrorMessage(errorMessage);
        } else {
          // Handle other errors (network issues, unexpected errors)
          console.error('GraphQL error:', error);
          setLoginFailed(true);
          setLoginErrorMessage('An error occurred. Please try again later.');
        }
      }
    };
    
    
    


  return (
    <div className='flex w-full h-screen bg-bgCol'>
        <div className='w-[55%] border-2 h-auto flex object-contain' style={{ backgroundImage: `url(${background})` }}>
          
        </div>
        <div className='w-[45%] h-full border-l-[0.5px] border-black'>
            <img className='mx-auto py-8' src={logo} alt='aqualona logo' />
            <div className='mx-auto px-20 mx-10 pt-20'>
              <h1 className='text-6xl font-bold text-brown mb-6'>Login</h1>
              
              {/* Email Field */}
              <div className='mb-4'>
                  <label className='block text-brown mb-2' htmlFor='email'>
                      Email
                  </label>
                  <input 
                      type='email' 
                      id='identifier'
                      className='w-full p-2 border rounded-md' 
                      value={identifier} 
                      onChange={e => setIdentifier(e.target.value)}
                      placeholder='Enter your email'
                  />
              </div>

              {/* Password Field */}
              <div className='mb-4'>
                  <label className='block text-brown mb-2' htmlFor='password'>
                      Password
                  </label>
                  <input 
                      type='password' 
                      id='password'
                      className='w-full p-2 border rounded-md' 
                      value={password} 
                      onChange={e => setPassword(e.target.value)}
                      placeholder='Enter your password'
                  />
              </div>
              
            <div className={`text-[#AC470E] ${loginFailed ? 'block' : 'hidden'}`}>
                <span className='text-[#AC470E]'>{loginErrorMessage}. All passwords must be at least 8 characters and includes at least one number or symbol.</span>
              </div>

               {/* Login Button */}
               <div className='my-10'>
                  {loading ? (
                    <button
                      className='w-full p-2 bg-brown text-white rounded-md uppercase'
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className='w-full p-2 bg-brown text-white rounded-md duration-300 hover:bg-light hover:text-brown transition uppercase'
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  )}
                </div>


          </div>
        </div>
    </div>
  )
}

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CATEGORY_PAGE_DATA_2 } from '../../api/brochure2/mutations';
import { v4 as uuidv4 } from 'uuid';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import {UPDATE_CATEGORY_PAGE_DATA} from '../../api/mutations'
import { GET_ALL_CATEGORIES, GET_CATEGORY_PAGE_DATA } from '../../api/GraphQL';
import { GET_ALL_CATEGORIES_2, GET_CATEGORY_PAGE_DATA_2 } from '../../api/brochure2/GraphQL';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import Pages from '../../animation/Pages';
import backArrow from '../../assets/back.svg';
import Loading from '../Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const S3_ENDPOINT = 'https://aqualona-img-file.ams3.digitaloceanspaces.com';
const S3_ACCESS_KEY = process.env.REACT_APP_DIGITAL_OCEAN_ACCESS_KEY_2; 
const S3_SECRET_KEY = process.env.REACT_APP_SPACE_SECRET; 
const S3_BUCKET_NAME = 'aqualona-img-file'; 

export default function EditAllCategory({brochure, type, client}) {
    const location = useLocation();
    const navigate = useNavigate();
    const [contentData, setContentData ] = useState(null)
    const {slug} = useParams();
    const [success, setSuccess] = useState(false);
    const { content } = location.state;
  const selectedMutation = brochure === '1' ?  UPDATE_CATEGORY_PAGE_DATA : UPDATE_CATEGORY_PAGE_DATA_2;

  const [newContent, setNewContent] = useState(content);
//   useEffect(() => {
//     if (content) {
//       setContentData(content);
//     }
//   }, [content]);
  const selectedQuery = brochure === '1' ? GET_CATEGORY_PAGE_DATA : GET_CATEGORY_PAGE_DATA_2;
  const [updateCategoryPage] = useMutation(selectedMutation);

  const {data, error, loading } = useQuery(selectedQuery);
  // console.log(brochure, 'the brochure')
  // console.log(data, 'thedata')
  const categoryAttributes = brochure === '1' ? data?.category?.data?.attributes : data?.categoryTwo?.data?.attributes;

// Later in the code
const { pageHeader, coverImageURL, paragraph } = categoryAttributes || {};



  if(loading){
    return <Loading />;
  }
   const handlePageHeaderChange = (e) => {
    // setPageHeader(e.target.value);
  };

  const s3Client = new S3Client({
    endpoint: S3_ENDPOINT,
    forcePathStyle: true,
    region: 'ams3',
    credentials: {
      accessKeyId: S3_ACCESS_KEY,
      secretAccessKey: S3_SECRET_KEY,
    },
  });
  





  const uploadImageToS3 = async (file) => {
    if (!file) {
      console.error('No file to upload.');
      return null;
    }

    const uniqueKey = `${uuidv4()}-${file.name}`;

    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: uniqueKey,
      Body: file,
      ACL: 'public-read',
      ContentType: 'image',
      ContentDisposition: 'inline',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const endpoint = `${S3_ENDPOINT}/${S3_BUCKET_NAME}/${uniqueKey}`;
      // console.log(endpoint);
      return endpoint;

    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };


  // Function to handle file input change
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadImageToS3(file);
      if (imageUrl) {
        const updatedValue = imageUrl;
    const updatedContent = {
      ...newContent,
      [slug]: updatedValue,
    };
    setNewContent(updatedContent);
    }
  }; }

  const handleUpdatedContent = (e) => {
    const updatedValue = e.target.value;
    const updatedContent = {
      ...newContent,
      [slug]: updatedValue,
    };
    setNewContent(updatedContent);
  };
  
  
  const handleClick = async (e) => {
    e.preventDefault();

        try {
            const { data, loading, error } = await updateCategoryPage({
                variables: {
                    data: {
                    pageHeader: newContent.pageHeader, 
                    paragraph: newContent.paragraph,
                    coverImageURL: newContent.coverImageURL
                    },
                },
                update: (cache, { data: { updateCategoryPage } }) => {
                    
                  
                    if (brochure === '1') {
                      cache.modify({
                        fields: {
                          category(existingCategory = {}) {
                            return {
                              ...existingCategory,
                              data: updateCategoryPage,
                            };
                          },
                        },
                      });
                    } else if (brochure === '2') {
                      cache.modify({
                        fields: {
                          categoryTwo(existingCategory = {}) {
                            return {
                              ...existingCategory,
                              data: updateCategoryPage,
                            };
                          },
                        },
                      });
                    }
                  },
            }, 

            );

            if(loading){
                return <Loading />
            }
            if(error) {
                console.error('not updated');
            }
            if(data){
                setSuccess(true);
                //  navigate(-1); // instead of this please go back to previous page
                setTimeout(() => {
                    navigate(-1);
                    
                  }, 1000);
                 
            }
            
        }
        catch {
            // console.error("Data error", error);
        }
    }
    


  if(!content) {
    return <Loading />
  }


  const isCoverImageSlug = slug === 'coverImageURL';

  return (
    <Pages>
    <div className='absolute left-0 bg-bgCol top-0 w-full h-full'>
      <div className='border-b-[0.5px] border-line p-4'>
        <Link className='flex space-x-2' to='..'>
          <img src={backArrow} alt='back arrow' />
          <span>Back to Home Page</span>
        </Link>
      </div>
      <div className='p-20 space-y-10'>
        {slug === 'coverImageURL' ? (
          <div className='flex space-x-20'>
            <span>{slug}</span>
            <label className='relative w-60 h-10 bg-gray-300 border-r-[0.5px] border-b-[0.5px] border-black rounded-md'>
              <input
                type='file'
                className='absolute w-full h-full opacity-0 cursor-pointer'
                onChange={handleImageChange}
              />
        {newContent && newContent[slug] ? (
          <img
            className='cursor-pointer hover:shadow-xl transition duration-300'
            src={newContent[slug]}
            alt='to edit'
            accept="image/jpeg, image/png, image/gif, image/webp" //accept only image files with these extensions
          />
              ) : (
                <span className='cursor-pointer absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500'>
                  Choose File
                </span>
              )}
            </label>
          </div>
        ) : (
          <>
            <div className='text-7xl text-[#C5BEB1]'>Edit Text</div>
            <div className='flex space-x-20'>
              <span>{slug}</span>
              <textarea
                type='textbox'
                value={newContent && newContent[slug]}
                onChange={handleUpdatedContent}
                placeholder={contentData && contentData[slug]}
                className='rounded-none w-60 focus:outline-none bg-[#E6E4E0] border-r-[0.5px] border-b-[0.5px] border-black'
              />
            </div>
          </>
        )}
      </div>
      <div className='absolute bottom-0 right-0 w-full border-t-[0.5px] border-line'>
        <div className='float-right bg-black px-20 py-10'>
          <button onClick={handleClick} className='text-white uppercase w-auto'>
            {success ? "Saved!" : "Save"}
          </button>
        </div>
      </div>
    </div>
  </Pages>
  );
}

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Loading from "./components/Loading";
import { useFetchUser } from "./lib/authContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./api/Apollo";
import { Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";



function App() {
  const { user, loading } = useFetchUser(); // Get the user object from the context

  if (loading) {
    return <Loading />;
  }

  return (

    <ApolloProvider client={client}>
      <AnimatePresence >
      <Routes>
        <Route path="*" element={user ? <Dashboard  /> : <Login />} />
      </Routes>
      </AnimatePresence>
    </ApolloProvider>

  );
}

export default App;

import React from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import Loading from '../../Loading';
import { useState } from 'react';
import { UPLOAD_PRODUCT_IMAGE } from '../../../api/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import Pages from '../../../animation/Pages';
import backArrow from '../../../assets/back.svg';
import { UPDATE_PRODUCT } from '../../../api/mutations';
import { UPDATE_PRODUCT_2 } from '../../../api/brochure2/mutations';
import { GET_PRODUCTS_BY_CATEGORY } from '../../../api/GraphQL';
import { GET_PRODUCTS_BY_CATEGORY_2 } from '../../../api/brochure2/GraphQL';

const S3_ENDPOINT = 'https://aqualona-img-file.ams3.digitaloceanspaces.com';
const S3_ACCESS_KEY = process.env.REACT_APP_DIGITAL_OCEAN_ACCESS_KEY_2;
const S3_SECRET_KEY = process.env.REACT_APP_SPACE_SECRET;
const S3_BUCKET_NAME = 'aqualona-img-file';



function getCurrentDateTimeWithMilliseconds() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}


export default function EditProduct({brochure}) {
  const location = useLocation();
  const { content } = location.state;

  const selectedRefreshQuery = brochure === '1' ? GET_PRODUCTS_BY_CATEGORY : GET_PRODUCTS_BY_CATEGORY_2

  const pathArray = location.pathname.split('/').map(decodeURIComponent);
  const category = pathArray[pathArray.length - 3];
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [newImage, setNewImage] = useState(content.product_images.map(image => image.attributes.url)); // image UI on image upload
  const [productContent, setProductContent] = useState({
    id: content.id,
    productID: content.productID,
    title: content.title,
    price: content.price,
    description: content.description,
    product_category: content.product_category,
    product_images: content.product_images.map(image => image.id),

  });

   console.log(productContent, "product content")
  const [uploadImageMutation] = useMutation(UPLOAD_PRODUCT_IMAGE);
  const selectedMutation = brochure === '1' ? UPDATE_PRODUCT : UPDATE_PRODUCT_2;
  const [updateProduct] = useMutation(selectedMutation);



// console.log(brochure, ": brochure")

  const s3Client = new S3Client({
    endpoint: S3_ENDPOINT,
    forcePathStyle: true,
    region: 'ams3',
    credentials: {
      accessKeyId: S3_ACCESS_KEY,
      secretAccessKey: S3_SECRET_KEY,
    },
  });

  const uploadImageToS3 = async (file) => {
    if (!file) {
      console.error('No file to upload.');
      return null;
    }
  
    const uniqueKey = `${uuidv4()}-${file.name}`;
  
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: uniqueKey,
      Body: file,
      ACL: 'public-read',
      ContentType: 'image',
      ContentDisposition: 'inline',
    };
  
    try {
      await s3Client.send(new PutObjectCommand(params));
      const endpoint = `${S3_ENDPOINT}/${S3_BUCKET_NAME}/${uniqueKey}`;
      // console.log(endpoint);
      return endpoint;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };
  

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadImageToS3(file);
      if (imageUrl) {
        try {
          // Call your GraphQL mutation to create a product image here
          const { data, loading, error } = await uploadImageMutation({
            variables: {
              productImageInput: {
                title: 'new image',
                url: imageUrl,
                publishedAt: getCurrentDateTimeWithMilliseconds(),
              },
              fields: ['id', 'title', 'url', 'publishedAt'],
            },
          });
  
          if (loading) {
            // Handle loading state
            return <Loading />
          }
  
          if (error) {
            console.error('Error creating product image:', error);
            // Handle the error state
          }
  
          if (data) {
            // Handle the successful creation of the product image here
            const imageId = data.createProductImage.data.id;
  
            // Add the image ID to the product_images array
            setProductContent((prevProductContent) => ({
              ...prevProductContent,
              product_images: [...prevProductContent.product_images, imageId],
            }));
            
            setNewImage((prevImages) => [ ...prevImages, imageUrl]);
          }
        } catch (error) {
          console.error('Error creating product image:', error);
          // Handle the error state
        }
      }
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductContent({
      ...productContent,
      [name]: value,
    });
  };


  const handleClick = async (e) => {
    e.preventDefault();
    // console.log(selectedMutation, 'update product query')
    const imageIds = productContent.product_images.map((image) => image.id);

    const prodCat = brochure === '1' ? 'product_category' : 'product_2_category';
    // console.log(productContent.product_images, "image IDs");
    // console.log(productContent, 'the product content')
    console.log(prodCat, 'product category')
    try {
      const { data, loading, error } = await updateProduct({
        variables: {
          id: productContent.id, // Assuming content.id holds the product ID
          data: {
            productID: productContent.productID,
            title: productContent.title,
            price: parseFloat(productContent.price),
            description: productContent.description,
            product_images: productContent.product_images,
            [prodCat]: productContent.product_category, // Include product_images here
          },
        },
        refetchQueries: [
          {
            query: selectedRefreshQuery,
            variables: productContent.product_category
          }
        ]
      //   update: (cache, {data: { updateProduct}}) => {
      //     if (brochure === '1') {
      //       cache.modify({
      //         fields: {
      //           product(existingCategory = {}) {
      //             return {
      //               ...existingCategory,
      //               data: updateProduct,
      //             };
      //           },
      //         },
      //       });
      //     } else if (brochure === '2') {
      //       cache.modify({
      //         fields: {
      //           brochure2Product(existingProduct = {}) {
      //             return {
      //               ...existingProduct,
      //               data: updateProduct,
      //             };
      //           },
      //         },
      //       });
      //     }
      //   }
      });
  
      if (data) {
        setSuccess(true);

        setTimeout(() => {
          navigate(-1); // Navigate after a delay of 1000ms (1 second)
          // Or navigate to a specific page: navigate(`/products/${previousPage}`);
        }, 1000);
        
      }
      if(loading){
        return <Loading />
      }
      if (error) {
        console.log(error, 'Error loading page content')
      }
    } catch (error) {
      console.error('Error updating product:', error);
      // Handle error or show an error message
      // window.location.reload();
    }
  };

  const handleDeleteImage = (indexToDelete) => {
    const updatedImages = newImage.filter((_, index) => index !== indexToDelete);
    setNewImage(updatedImages);

    setProductContent((prevProduct) => {
      const updatedImagesProduct = prevProduct.product_images.filter((_, index) => index !== indexToDelete);
      return {
        ...prevProduct,
        product_images: updatedImagesProduct,
      };
    });
  }; 
  

const handleBackButton = () => {
  navigate(-1);
}

return (
  <Pages>
    <div className='absolute z-10 top-0 left-0 w-full h-full bg-[#E6E4E0] space-y-2'>
      <div className='border-b-[0.5px] border-line p-4'>
        <button onClick={handleBackButton} className='flex space-x-2'>
          <img src={backArrow} alt='back arrow' />
          <span>Back to {category}</span>
        </button>
      </div>

      <h1 className='text-7xl text-[#C5BEB1] py-10 px-20 '>Edit Product</h1>

      <div className='flex w-full space-x-10 px-20'>
      <div className='w-1/3 space-y-6'>
  {/* Product Info */}
  <div className='w-full flex'>
    <span className='w-1/3'>Product ID</span>
    <input
      name='productID'
      value={productContent.productID}
      onChange={handleChange}
      className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
      placeholder={productContent.productID}
      type='textbox'
    />
  </div>
  <div className='w-full flex'>
    <span className='w-1/3'>Product Name</span>
    <input
      name='title'
      value={productContent.title}
      onChange={handleChange}
      className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
      placeholder={productContent.title}
      type='textbox'
    />
  </div>
  <div className='w-full flex'>
    <span className='w-1/3'>Price (£)</span>
    <input
      name='price'
      onInput={(e) => {
        const value = e.target.value;
        const formattedValue = value.replace(/[^\d.]/g, ''); // Remove non-digit characters except dot
        e.target.value = formattedValue;
      }}
      value={productContent.price}
      onChange={handleChange}
      className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
      placeholder={productContent.price ? productContent.price : '0.00'}
      type='textbox'
    />
  </div>
  <div className='w-full flex'>
    <span className='w-1/3'>Product Description</span>
    <textarea
      name='description'
      value={productContent.description}
      onChange={handleChange}
      className='w-2/3 bg-transparent h-40 text-[#C5BEB1] text-sm italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
      placeholder={productContent.description}
      type='textbox'
    />
  </div>
</div>
        <div className='w-2/3 h-full justify-between flex flex-col'>
          {/* Product Photos */}
          <div>
          <h2 className='text-[#C5BEB1] border-b-[0.5px] border-[#C5BEB1] mb-8'>
            Product Photos
          </h2>
          <div className="grid grid-cols-4 grid-rows-2 h-full">
            {/* Display uploaded product images */}
            {newImage && newImage.length > 0 && newImage.map((image, index) => (
              <div key={index} className="relative group">
                <div className="w-[200px] h-[200px] flex justify-center">
                  <img src={image} alt={`Uploaded Product ${index}`} className="w-full h-full object-cover hover:object-contain" />
                </div>
                <div className="hidden flex group-hover:block transition-opacity opacity-0 absolute bottom-0 z-10 left-0 hover:bg-gray-400 rounded-t w-full bg-gray-300 text-white py-1 group-hover:opacity-100">
                  <div className='flex text-center'>
                    <div className=' w-1/2'>
                      <a className='w-full' target="_blank" rel="noreferrer" href={image} download={`image_${index}.jpg`}>
                        <button> View </button>
                      </a>
                    </div>
                    <div className='w-1/2  font-thin border-l border-white'>
                      <button className='flex w-auto m-auto text-center opacity-40 hover:opacity-100' onClick={() => handleDeleteImage(index)} >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <label className='flex bg-[#DCD9D3] text-[#C5BEB1] hover:text-[#DCD9D3] m-auto shadow-md transition duration-500 hover:bg-[#C5BEB1] w-[120px] h-[120px] rounded relative'>
              <span className='m-auto uppercase  text-2xl '>{newImage && newImage.length === 6 ? 'Full' : '+'}</span>
              <input
                type="file"
                onChange={handleImage}
                className="absolute rounded-md m-auto opacity-0 cursor-pointer h-full w-full"
                accept="image/jpeg, image/png, image/gif, image/webp" //accept only image files with these extensions
                multiple // Allow multiple file selection
                disabled={newImage && newImage.length === 6} // Disable input if newImage has 6 items
              />
          </label>
          </div>
        </div>
        <div className=' pt-8'>
          <ul className="list-disc list-inside text-xs">
            <li>Ensure image formats are PNG, JPEG, JPG, or WEBP to prevent compatibility issues.</li>
            <li> Upload images with a max width dimension of 1200px - larger pixel dimensions will cause slower loading speeds.</li>
            <li>Convert to WEBP format for best performance - <a className='underline' target="_blank" rel="noreferrer"  href='https://tiny-img.com/webp/'>TinyIMG Web Converter Tool</a></li>
            <li>Avoid uploading images larger than 1MB.</li>
            <li>The displayed uploaded image adheres to a 1:1 ratio, reflecting how it will appear in the product list.</li>
          </ul>


        </div>
        </div>
        
      </div>

      {/* Save button */}
      <div className='absolute bottom-0 right-0 w-full border-t-[0.5px] border-line'>
        <div className='float-right bg-black px-20 py-10'>
          <button onClick={handleClick} className='text-white uppercase w-auto'>
          {success ? "Saved!" : "Save"}
          </button>
        </div>
      </div>

      <Outlet />
    </div>
  </Pages>
);
}

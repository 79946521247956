import React from 'react'
import loading from '../assets/loading.gif' 

const Loading = () => {
  return (
    <div className='flex w-full h-full pb-20'>
        <div className='m-auto w-[20px] h-[20px]'>
            <img className='m-auto w-full h-full object-contain' src={loading} alt='animation of loading cirlce' />
        </div>
        
    </div>
  )
}
export default Loading;

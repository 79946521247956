import React from 'react'
import Pages from '../animation/Pages'
import CategoryNav from '../components/dashboard/products/CategoryNav'
import { Outlet } from 'react-router-dom'
import { GET_ALL_CATEGORIES } from '../api/GraphQL'
import Loading from '../components/Loading'
import { useQuery } from '@apollo/client'
export default function Products() {
    const { loading, error, data } = useQuery(GET_ALL_CATEGORIES);

    if (loading) {
      return <Loading />;
    }
  
    if (error) {
      return <p>Error: {error.message}</p>;
    }

const categories = data.productCategories.data;
  return (
    <Pages>
        <div className='w-full h-full flex'>
            <div className='w-[100%] h-full flex'>
                <div className='w-[15%]'>
                    <CategoryNav categories={categories} />
                </div>
                <div className='w-[80%] p-20'>
                    {/* category content */}
                    <Outlet />
                </div>
            </div>
        </div>
        
        
    </Pages>
  )
}

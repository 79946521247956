import { motion } from 'framer-motion'
import React from 'react'

export default function Pages({children}) {
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1}}
    exit={{opacity: 0}}
    transition={{
        type: "spring",
        stiffness: 260,
        damping: 80
    }}
    className='w-full h-full flex'>
        {children}
    </motion.div>
  )
}

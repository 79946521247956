import { gql } from "@apollo/client";


// login request
const LOGIN_MUTATION = gql`
mutation Login($identifier: String!, $password: String!) {
  login(input: { identifier: $identifier, password: $password }) {
    jwt
    user {
      id
      username
      email
    }
  }
}
`;


// query - about, home, category and products (all)

const GET_ABOUT_US_DATA = gql`
  query {
    aboutUs {
      data {
        attributes {
          pageHeader
          paragraph1
          paragraph2
          slogan
          image1URL
          image2URL
          landscapeImageURL
        }
      }
    }
  }
`;

const GET_HOME_DATA = gql`
  query {
    home {
      data {
        attributes {
          pageHeader
          subHeader
          button
          description
          coverImageURL
        }
      }
    }
  }
`;

const GET_ALL_CATEGORIES = gql(`
  query {
    productCategories(sort: "order") {
      data {
        id
        attributes {
          order
          title
          iconURL
          coverImageURL
        }
      }
    }
  }
`);

const GET_CATEGORY_PAGE_DATA =gql(`
query {
  category {
    data {
      attributes {
        pageHeader
        paragraph
        coverImageURL
      }
    }
  }
}
`)

const GET_PRODUCTS_BY_CATEGORY = gql(`
  query getProductCategory($id: ID!) {
    productCategory(id: $id) {
      data {
        id
        attributes {
          title
          coverImageURL
          iconURL
          order
          products(pagination: {limit: 80}, sort: "orderBy") {
            data {
              id
              attributes {
                publishedAt
                productID
                title
                price
                description
                product_images {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
`);

const GET_PRODUCT_BY_ID = gql(`
  query getProduct($id: ID!) {
    product(id: $id) {
      data {
        id
        attributes {
          productID
          title
          description
          product_images {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`);

const GET_PRODUCT_CATEGORY_BY_ID = gql`
query productCategory($id: ID!){
  product(id: $id){
    data {
      attributes {
        product_category {
          data {
            id
          }
        }
      }
    }
  }
}
`;

export {GET_ABOUT_US_DATA, LOGIN_MUTATION, GET_HOME_DATA, GET_ALL_CATEGORIES, GET_CATEGORY_PAGE_DATA, GET_PRODUCTS_BY_CATEGORY, GET_PRODUCT_BY_ID, GET_PRODUCT_CATEGORY_BY_ID};
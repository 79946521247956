import { gql } from "@apollo/client";




// query - about, home, category and products (all)



const GET_HOME_DATA_2 = gql`
  query {
    homeTwo {
      data {
        attributes {
          pageHeader
          subHeader
          button
          description
          coverImageURL
        }
      }
    }
  }
`;

const GET_ALL_CATEGORIES_2 = gql(`
  query {
    product2Categories(sort: "order") {
      data {
        id
        attributes {
          order
          title
          iconURL
          coverImageURL
        }
      }
    }
  }
`);

const GET_CATEGORY_PAGE_DATA_2 =gql(`
query {
  categoryTwo {
    data {
      attributes {
        pageHeader
        paragraph
        coverImageURL
      }
    }
  }
}
`)

const GET_PRODUCTS_BY_CATEGORY_2 = gql(`
  query getProductCategory($id: ID!) {
    product2Category(id: $id ) {
      data {
        id
        attributes {
          title
          coverImageURL
          iconURL
          order
          brochure_products(pagination: { limit: 80 } , sort: "orderBy") {
            data {
              id
              attributes {
                publishedAt
                productID
                title
                price
                description
                product_images {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }  
`);

const GET_PRODUCT_BY_ID_2 = gql(`
  query getProduct($id: ID!) {
    Brochure2Product(id: $id) {
      data {
        id
        attributes {
          productID
          title
          description
          price
          product_images {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`);

const GET_PRODUCT_CATEGORY_BY_ID_2 = gql`
query product2Category($id: ID!){
  brochure2Product(id: $id){
    data {
      id
      attributes {
        product_2_category {
          data {
            id
          }
        }
      }
    }
  }
}
`;

export { GET_HOME_DATA_2, GET_ALL_CATEGORIES_2, GET_CATEGORY_PAGE_DATA_2, GET_PRODUCTS_BY_CATEGORY_2, GET_PRODUCT_BY_ID_2, GET_PRODUCT_CATEGORY_BY_ID_2};
import React, { useState, useEffect } from 'react';
import downArrow from '../../assets/Arrow down.svg';
import { gql, useQuery } from '@apollo/client';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AnimatePresence, motion } from 'framer-motion'
const GET_ALL_PRODUCTS = gql`
  query products {
    products (pagination: { limit: 2}, sort: "publishedAt:desc") {
      data {
        id
        attributes {
          publishedAt
          productID
          title
          product_images {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ALL_PRODUCTS_2 = gql`
query {
  brochure2Products(pagination: { limit: 2}, sort: "publishedAt:desc") {
    data {
      id
      attributes {
        publishedAt
        productID
        title
        product_images {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

const calculateTimeDifference = (publishedAt) => {
  const currentDate = new Date();
  const publishedDate = new Date(publishedAt);
  const timeDifference = currentDate - publishedDate;

  const minutes = Math.floor(timeDifference / (1000 * 60));
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
  const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));

  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
};


const formatPublishedAt = (publishedAt) => {
  const dateObj = new Date(publishedAt);
  return `${dateObj.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })} ${dateObj.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })}`;
};


export default function TopBar({ onPublishClick }) {
  const { loading, error, data } = useQuery(GET_ALL_PRODUCTS);
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_ALL_PRODUCTS_2);

  const [showDropdown, setShowDropdown] = useState(false);
  const [latestProduct, setLatestProduct] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);

  useEffect(() => {
    if (loading || loading2) {
      setTimeStamp('Loading..');
    } else if (error || error2) {
      setTimeStamp('Error Loading Products');
    } else {
      // Combine products from both queries into a single array
      const allProducts = [
        ...(data && data.products && data.products.data ? data.products.data : []),
        ...(data2 && data2.brochure2Products && data2.brochure2Products.data ? data2.brochure2Products.data : []),
      ];

      // Find the latest product from the combined array
      const latest = allProducts.reduce((latest, product) => {
        const productPublishedAt = product.attributes.publishedAt;

        if (productPublishedAt && (!latest || productPublishedAt > latest.attributes.publishedAt)) {
          return product;
        }

        return latest;
      }, null);

      if (latest && !latestProduct) {
        const timeAgo = calculateTimeDifference(latest.attributes.publishedAt);
        setTimeStamp(` ${timeAgo}`);
        setLatestProduct(latest);
      }
    }
  }, [loading, loading2, error, error2, data, data2, latestProduct]);
  console.log(data2, "brochure 2 data")
  return (
    <div className='w-full h-full flex relative z-4 '>
      <div className='z-40  bg-bgCol border-b-[0.5px] border-black w-full h-full'>
      <div className='text-xs flex m-auto space-x-2 absolute top-0 w-full h-full'>
        <div className='m-auto flex  pr-[7%]'>
        <span className=''>Last Published - { timeStamp}</span>
        <div className='relative '>
          <button onClick={() => setShowDropdown(!showDropdown)}>
            <img className={`transition-all duration-700 ${showDropdown ? 'scale-y-[-1]' : ''}`} src={downArrow} alt='down arrow' />
          </button>
          
        </div>
        </div>
      </div>
      <div className='absolute top-0 right-0 h-full text-xs flex w-1/3'>
        <p className='w-full m-auto'>Preview: </p>
        <a
          href='https://ebrochureaqualona.co.uk'
          target='_blank'
          rel='noopener noreferrer'
          className='uppercase text-white border-r-[0.5px] border-white hover:text-black bg-black px-8 my-auto h-full w-full flex hover:bg-white transition duration-300'
        >
          <div className='flex m-auto'>Brochure 1</div>
        </a>
        <a
          href='https://ebrochureaqualona2.co.uk'
          target='_blank'
          rel='noopener noreferrer'
          className='uppercase text-white hover:text-black bg-black px-8 my-auto h-full w-full flex hover:bg-white transition duration-300'
        >
          <div className='flex m-auto'>Brochure 2</div>
        </a>
      </div>

          </div>
          <AnimatePresence mode='wait'>
          {showDropdown && latestProduct && (
           
            <motion.div 
      key="modal" // Ensure to use a key prop
      initial={{ y: '-0%' }} 
      animate={{ y: '100%' }} 
      exit={{ y: '-5000%' }}
      transition={{ duration: 0.2 }} 
       className={`pr-[7%] absolute bottom-0 left-0  w-full right-0 text-xs transition duration-700  flex text-white h-[120px] z-20 `}>
              {/* Render details of the latest product here */}
              <div className='flex bg-black p-2 mx-auto justify-between'>
              <div className='my-auto w-40'>
                <p className='font-light text-[9px] pb-2'>{latestProduct.attributes.productID}</p>
                <p className='text-xs'>{latestProduct.attributes.title}</p>
                <p className='text-[9px]'>{formatPublishedAt(latestProduct.attributes.publishedAt)}</p>
              </div>
              {latestProduct.attributes.product_images.data[0]?.attributes?.url && (
                <LazyLoadImage effect='blur' width={100} height={100} className='w-[100px] h-[100px] object-cover' src={latestProduct.attributes.product_images.data[0].attributes.url} alt='latest product image'/>
              )}
              </div>
            </motion.div>
            
           )} 
           </AnimatePresence>
    </div>
  );
}

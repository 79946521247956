import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from './Landing'
import TopBar from '../components/dashboard/TopBar'
import Menu from '../components/dashboard/Menu'
import Home from './Home'
import { AnimatePresence } from 'framer-motion'
import About from './About'
import Edit from '../components/Requests/Edit'
import Settings from './Settings'
import Products from './Products'
import Categories from '../components/dashboard/products/Categories'
import Category from '../components/dashboard/products/Category'
import Add from '../components/Requests/Add'
import Delete from '../components/Requests/Delete'
import EditProduct from '../components/dashboard/products/EditProduct'
import Products2 from './Products2'
import Categories2 from '../components/dashboard/products2/Categories'
import EditCategory from '../components/Requests/EditCategory'
import EditAllCategory from '../components/Requests/EditAllCategory'
import { GET_PRODUCTS_BY_CATEGORY } from '../api/GraphQL'
import { GET_PRODUCTS_BY_CATEGORY_2 } from '../api/brochure2/GraphQL'
export default function Dashboard() {

  return (

    <div className='w-screen h-screen min-w-[1200px] min-h-[800px] flex bg-bgCol'>
        
        {/* naivgation - left side of screen  */}
        <div className='w-[7%]'>
            <Menu />
        </div>
        
        {/* status bar at the top of screen full width  */}
        <div className='flex flex-col w-[93%] h-full'>
            <div className='w-full h-[5vh]  '>
                  <TopBar />  
            </div>
            <div className='h-[95vh] relative'>
                <AnimatePresence >
                <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/home' element={<Home />} >
                        <Route path='edit/:slug' element={<Edit page='about' />} />
                    </Route>
                    <Route path='/about' element={<About />} > 
                        <Route path='edit/:slug' element={<Edit />} />
                    </Route>
                    <Route path='/products' element={<Products />} >
                        <Route path='' element={<Categories />} />
                        <Route path=':id/:slug/edit' element={<EditCategory brochure='1' />} />
                        <Route path='edit/:slug' element={<EditAllCategory brochure='1' />} />
                        <Route path=':id' element={<Category brochure={'1'} query={GET_PRODUCTS_BY_CATEGORY} />} />
                        <Route path=':id/add'  element={<Add brochure='1' />} />
                        <Route path=':id/delete/:slug' element={<Delete />} />
                        <Route path=':id/edit/:slug' element={<EditProduct brochure='1' />} />
                    </Route>
                    <Route path='/products-2' element={<Products2 />} >
                        <Route path='' element={<Categories2 />} />
                        <Route path='edit/:slug' element={<EditAllCategory brochure='2' />} />
                        <Route path=':id/:slug/edit' element={<EditCategory brochure={'2'} />} />
                        <Route path=':id' element={<Category brochure={'2'} query={GET_PRODUCTS_BY_CATEGORY_2} />} />
                        <Route path=':id/add'  element={<Add brochure='2' />} />
                        <Route path=':id/delete/:slug' element={<Delete />} />
                        <Route path=':id/edit/:slug' element={<EditProduct brochure='2' />} />
                    </Route>
                    <Route path='/settings' element={<Settings />} />
                </Routes>
                </AnimatePresence>
            </div>
        </div>
    </div>

 
  )
}

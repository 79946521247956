import React from 'react';
import { DragDropContext, Draggable} from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable'
import drag from '../../../assets/Move.svg';
import ProductOptions from '../../ProductOptions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Drag() {
  return (
    <div>
      <img src={drag} alt='two by three dots to drag' />
    </div>
  );
}

export default function DraggableProducts({ products, onReorder, categoryId }) {
  const productList = products.map((product) => {
    const productID = product.attributes.productID;
    const id = product.id;
    const title = product.attributes.title;
    const price = product.attributes.price;
    const description = product.attributes.description || 'No description';
    const publishedAt = product.attributes.publishedAt;
    const product_images = product.attributes.product_images.data;
    const orderBy = product.attributes.orderBy;
    const product_category = categoryId;
    return {
      productID: productID,
      id: id,
      title,
      price,
      description,
      product_category,
      product_images,
      publishedAt,
      orderBy
    };
  });
console.log(productList[1], 'product list first element');
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Dragged outside the list
    }

    const { source, destination } = result;
    const reorderedProducts = Array.from(productList);
    const [movedItem] = reorderedProducts.splice(source.index, 1);
    reorderedProducts.splice(destination.index, 0, movedItem);

    const updateProducts = reorderedProducts.map((product, index) => ({
      ...product,
      orderBy: index + 1,
    }));
    // Call the onReorder function with the updated order
    onReorder(updateProducts);

  };

  function handleImageError() {
    const imageContainer = document.getElementById('imageContainer');
    const errorDiv = document.getElementById('errorDiv');
  
    if (imageContainer && errorDiv) {
      imageContainer.style.display = 'none';
      errorDiv.style.display = 'block';
    } else {
      console.error('Image container or error div not found!');
    }
  }
  

function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)} (${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()})`;
  return formattedDate;
}



  return (
    <div className=' w-full pb-20'>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="products">
          {(provided) => (
            <ul ref={provided.innerRef} {...provided.droppableProps}>
              {productList.map((product, index) => (
                <Draggable key={product.id} draggableId={product.id} index={index}>
                  {(provided, snapshot) => (
                    <li
                      key={`1__product_${index}`}
                      className={`flex w-full border-b-[0.5px] border-black py-2 h-20 ${
                        snapshot.isDragging ? 'bg-[#DCD9D3]' : 'bg-transparent'
                      }`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div>
                        <Drag />
                      </div>
                      <div className='pl-4 text-xs flex space-x-4 w-full justify-between'>
                        <span className='w-[10%]'>{product.productID}</span>
                        <span className='w-[15%]'>{product.title}</span>
                        <p className='w-[55%]'>{product.description.slice(0, 120)}...</p>

                        <div className='relative w-[10%] rounded overflow-hidden'>
                        {product.product_images &&
                          product.product_images &&
                          Array.isArray(product.product_images) &&
                          product.product_images.length > 0 ? (
                            <LazyLoadImage
                              effect='blur'
                              className="h-full w-full object-cover"
                              src={product.product_images[0].attributes.url}
                              alt="example"
                              onError={handleImageError}
                            />
                          ) : (
                            <div
                              className="h-full w-full"
                              style={{ backgroundColor: '#C5BEB1' }}
                            ></div>
                          )
                        }

                        <div className='absolute bottom-0 w-full bg-black opacity-50 text-white text-center font-bold py-2'>
                          {product.product_images ? product.product_images.length : 0}
                        </div>


                        </div>
                        <div className='w-[25%]'>
                          <p className='font-semibold'>Published: </p>
                          <span>
                            {formatDate(product.publishedAt)}
                            </span>
                          </div>
                        <div className='w-1/5 flex'>
                          <div className='m-auto'>
                            <ProductOptions  label={['Delete Product', 'Edit']}
                             link={[`delete/${product.id}`, `edit/${product.id}`]}
                             content={product} initialDropdownVisible={false} />
                          </div>
                          
                        </div>
                        
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
}

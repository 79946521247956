import { setContext } from '@apollo/client/link/context';
import { getTokenFromCookie } from '../lib/auth';
import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';



const errorLink = onError(({ graphQLErrors, networkErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkErrors) {
    console.log(`[Network error]: ${networkErrors}`);
  }
});

const setAuthorizationHeader = (token) => {
    client.setLink(
      setContext((_, { headers }) => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      }))
    );
    // console.log("link set");
  };

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getTokenFromCookie();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
  
    }
  });
  const apiURL = process.env.REACT_APP_API_URL; // Make sure the variable name is correct

const link = from([
    authLink,
  errorLink,
  new HttpLink({ uri: `${process.env.REACT_APP_STRAPI_URL}/graphql` }),
]);
const cache = new InMemoryCache({
  typePolicies: {
  
 
    Product2CategoryEntity: {
      fields: {
        attributes: {
          merge(existing = {}, incoming) {
            // Custom logic to merge existing and incoming data
            return {
              ...existing,
              ...incoming,
              // Add specific merge logic for nested fields if needed
            };
          },
        },
      },
    },
    ProductCategoryEntity: {
      fields: {
        attributes: {
          merge(existing = {}, incoming) {
            // Custom logic to merge existing and incoming data
            return {
              ...existing,
              ...incoming,
              // Add specific merge logic for nested fields if needed
            };
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: link,
  cache: cache,
});

export { client, setAuthorizationHeader };

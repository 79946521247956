import { gql } from "@apollo/client"
const brochure1ProductFragment = gql`
    fragment NewProduct on Product {
    title
    price
    productID
    orderBy
    product_images {
        data {
        id
        attributes {
            url
        }
        }
    }
    product_category {
        data {
        id 
        attributes {
            title
        }
        }
    }
    description
    }
`;

const brochure2ProductFragment = gql`
fragment New2Product on Brochure2Product {
    title
    price
    productID
    orderBy
    product_images {
      data {
        id
        attributes {
          url
        }
      }
    }
    product_2_category {
      data {
        id 
        attributes {
          title
        }
      }
    }
    description
  }
`
const productImageFragment = gql`
fragment NewProductImage on ProductImage {
  url
  title

}
`; 

export {brochure1ProductFragment, brochure2ProductFragment, productImageFragment}

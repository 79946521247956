import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import drag from '../../../assets/Move.svg'
import Options from '../../Options';
import { StrictModeDroppable } from './StrictModeDroppable';

function Drag() {
  return (
    <div>
        <img src={drag} alt='two by three dots to drag' />
    </div>
  )
}

export default function DraggableList({ categories, onReorder, property, brochure }) {
  // console.log(property, 'this is the property value')
  // console.log(categories, 'categories id?')
  const categoryList = categories.map((category) => {
    const id = category.id
    const title = category.attributes.title;
    const coverImageURL = category.attributes.coverImageURL;
    const iconURL = category.attributes.iconURL;
    const order = category.attributes.order;
    return {
      categoryId: id,
      title: title,
      coverImageURL: coverImageURL,
      iconURL: iconURL,
      order: order
    };
  });
// console.log(categoryList, 'list')
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Dragged outside the list
    }
   
    const { source, destination } = result;
    const reorderedCategories = Array.from(categories);
    const [movedItem] = reorderedCategories.splice(source.index, 1);
    reorderedCategories.splice(destination.index, 0, movedItem);
// console.log(reorderedCategories, 'reordered')
    // Update the order property of categories based on their new positions
    const updatedCategories = reorderedCategories.map((category, index) => ({
      ...category,
      order: index + 1, // Assuming order starts from 1
    }));

    // Call the onReorder function with the updated order
    onReorder(updatedCategories);
    console.log(updatedCategories, 'updated cats')
  };

  return (
    <div className='pr-10 w-full'>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="categories">
          {(provided) => (
            <ul ref={provided.innerRef} {...provided.droppableProps}>
              {categoryList
                 // Sort by category order
                .map((category, index) => (
                  <Draggable
                    key={`category_${index}`}
                    draggableId={`category_${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        key={`1__category_${index}`}
                        className={`flex w-full border-b-[0.5px] border-black py-2 ${
                          snapshot.isDragging ? 'bg-[#DCD9D3]' : 'bg-transparent'
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div>
                          <Drag />
                        </div>
                        <div className='pl-4 flex w-full justify-between'>
                          <span>{category.title}</span>
                          <Options
                            label={['Edit Name', 'Replace Icon']}
                            link={[`${category.title}/title/edit`,`${category.title}/iconURL/edit`]}
                            property='Title'
                            initialDropdownVisible={false}
                            content={category}
                            contentLabel={property}
                          />
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
}  

import { gql } from "@apollo/client"

const UPDATE_HOME_PAGE = gql(`
mutation UpdateHomePage($data: HomeInput!) {
    updateHome(data: $data) {
      data {
        attributes {
          pageHeader
          subHeader
          button
          description
          coverImageURL
        }
      }
     
    }
  }
`);

const UPDATE_CATEGORY_DATA = gql`
mutation updateCategory($id: ID!, $data: ProductCategoryInput!){
  updateProductCategory(id: $id, data: $data){
    data {
      id
      attributes {
        order
        coverImageURL
        iconURL
      }
    }
  }
}
`;

const UPDATE_CATEGORY_ORDER =gql`
mutation updateCatory($id: ID!, $order: Int!){
  updateProductCategory(id: $id, data: {order: $order}){
    data {
      id
      attributes {
        order
      }
    }
  }
}
`
const UPDATE_PRODUCT_ORDER = gql`
mutation updateProduct($id: ID!, $orderBy: Int!){
  updateProduct(id: $id, data: { orderBy: $orderBy }){
    data {
      id
      attributes {
        orderBy
      }
    }
  }
}
`


const updateHomeImage = gql(`
mutation updateContentItem($id: ID!, $coverImage: ID!) {
    updateContent(id: $id, input: { coverImage: $coverImage }) {
      id
      coverImage {
        id
      }
    }
  }
`);


const UPDATE_CATEGORY_PAGE_DATA = gql`
  mutation UPDATE_CATEGORY_PAGE_DATA($data: CategoryInput!) {
    updateCategory(data: $data) {
      data {
        attributes {
          pageHeader
          paragraph
          coverImageURL
        }
      }
    }
  }
`;


const UPDATE_ABOUT_PAGE = gql(`
mutation UpdateAboutPage($data: AboutUsInput!) {
    updateAboutUs(data: $data) {
      data {
        attributes {
          pageHeader
          paragraph1
          paragraph2
          slogan
          image1URL
          image2URL
          landscapeImageURL
        }
      }
     
    }
  }
`);

const UPLOAD_PRODUCT_IMAGE = gql`
mutation AddProductImage($productImageInput: ProductImageInput!) {
  createProductImage(data: $productImageInput) {
    data {
    id
      attributes {
        url
        title
        publishedAt
      }
    }
  }
}

`;

//add new product

const ADD_PRODUCT = gql`
  mutation AddProduct($productInput: ProductInput!) {
    createProduct(data: $productInput) {
      data{ 
        attributes {
          publishedAt
          productID
          title
          price
          description
          product_category {
            data {
              id
            }
          }
          product_images {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const DELETE_PRODUCT = gql`
mutation DeleteProduct($id: ID!) {
  deleteProduct(id: $id) {
    data {
      id 
    }
  }
}
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $data: ProductInput!) {
    updateProduct(id: $id, data: $data) {
      data {
        id
        attributes {
          productID
          title
          description
          product_category {
            data {
              id
            }
          }
          product_images {
            data {
              id
            }
          }
        }
      }
    }
  }
`;




export {UPDATE_HOME_PAGE,
   DELETE_PRODUCT,
    UPLOAD_PRODUCT_IMAGE,
     UPDATE_ABOUT_PAGE,
      ADD_PRODUCT,
       UPDATE_PRODUCT,
        UPDATE_CATEGORY_PAGE_DATA,
      UPDATE_CATEGORY_DATA, UPDATE_CATEGORY_ORDER, UPDATE_PRODUCT_ORDER };

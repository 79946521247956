import React from 'react'
import dashboard_img from '../assets/dashboard-img.png'
import { motion } from 'framer-motion'
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';


export default function Landing() {
  return (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1}}
        exit={{opacity: 0}}
        transition={{
            type: "spring",
            stiffness: 260,
            damping: 80
        }}
        className='flex w-full h-full '>
            <div className='h-full my-auto flex flex-col w-1/3'>
                <div className='m-auto uppercase space-y-8 pb-8'>
                    <h1 className=' text-5xl font-light' >Welcome back, <br /> {Cookies.get('username')}</h1>
                    <div className='flex space-x-4'>
                        <Link to='/products' className=' uppercase border-2 border-black p-4 transition duration-500 hover:bg-black hover:text-[#AEA89B]'>View Products</Link>
                        <Link to='/products-2' className=' uppercase border-2 border-black p-4 transition duration-500 hover:bg-black hover:text-[#AEA89B]'>View Brochure 2 Products</Link>
                    </div>
                </div>
            </div>
            <div className='w-2/3 h-full'>
                <img className='w-full h-full object-cover'src={dashboard_img} alt="outline drawing of a bathroom with a mirror, bathtub, shower, sink and toilet" />
            </div>
            
        </motion.div>
  )
}

import React from 'react'
import Options from '../components/Options'
import { unsetToken } from '../lib/auth'
import Cookies from 'js-cookie';
export default function Settings() {


    const handleLogout = () => {
        unsetToken();
        window.location.reload(false);
    }
  return (
    <div className='p-20 w-full h-full flex flex-col justify-between'>
        <div className='space-y-8'>
            <h1 className='text-4xl font-bold'>Settings</h1>
            <h2 className='border-b-[0.5px] border-[#A59C8C] text-[#A59C8C] border-dashed py-2'>User Information</h2>
            <div className='w-full'>
                <ul className='w-full text-sm space-y-8'>
                    <li className='flex w-full'>
                        <h3 className=' font-bold w-[10%]'>User</h3> 
                        <div className='flex justify-between w-full pl-20'>
                            <span>{Cookies.get('username')}</span>
                            <Options />
                        </div>
                    </li>
                    <li className='flex'>
                        <h3 className='font-bold w-[10%]'>Email</h3>
                        <div className='flex justify-between w-full pl-20'>
                            <span>{Cookies.get('id')}</span>
                            <Options />
                        </div>
                    </li>
                    <li className='flex'>
                        <h3 className='font-bold w-[10%]'>Permissions</h3>
                        <div className='flex justify-between w-full pl-20'>
                            <span>Admin</span>
                            <Options />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className='text-xs space-y-4 '>
            <p>If you have any technical issues or wish to request modifications to the E-brochure design, please reach out to us at email@address.com.</p>
            <button onClick={handleLogout}><span className='uppercase hover:underline'>Log Out</span></button>
        </div>
    </div>
  )
}

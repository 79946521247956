import React from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import Loading from '../Loading';
import { useState } from 'react';
import { ADD_PRODUCT, UPLOAD_PRODUCT_IMAGE } from '../../api/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import Pages from '../../animation/Pages';
import backArrow from '../../assets/back.svg';
import {brochure1ProductFragment, brochure2ProductFragment, productImageFragment} from '../../api/Fragments'
import { ADD_PRODUCT_2 } from '../../api/brochure2/mutations';
import { GET_PRODUCTS_BY_CATEGORY } from '../../api/GraphQL';
import { GET_PRODUCTS_BY_CATEGORY_2 } from '../../api/brochure2/GraphQL';
const S3_ENDPOINT = 'https://aqualona-img-file.ams3.digitaloceanspaces.com';
const S3_ACCESS_KEY = process.env.REACT_APP_DIGITAL_OCEAN_ACCESS_KEY_2; 
const S3_SECRET_KEY = process.env.REACT_APP_SPACE_SECRET; 
const S3_BUCKET_NAME = 'aqualona-img-file'; 

function getCurrentDateTimeWithMilliseconds() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

export default function Add({ brochure }) {
  const loc = useLocation();
  const { categoryId, productCount } = loc.state || {};;
  const navigate = useNavigate();

  // category associated with product to add
  const pathArray = loc.pathname.split('/').map(decodeURIComponent);
  const category = pathArray[pathArray.length - 2];
  const [newImage, setNewImage] = useState([]);//image UI on image upload
  const [success, setSuccess] = useState(false);
  const selectedFragment = brochure === '1' ? brochure1ProductFragment : brochure2ProductFragment;

  const selectedRefreshQuery = brochure === '1' ? GET_PRODUCTS_BY_CATEGORY : GET_PRODUCTS_BY_CATEGORY_2

  console.log(categoryId, 'cat id');
  // const { refetch } = useQuery(selectedRefreshQuery, {
  //   variables: { id: categoryId },
  //   skip: true, // Optionally skip the query on initial render if you only need refetch
  // });
  const [newProduct, setNewProduct] = useState({
    productID: '',
    title: '',
    description: '',
    price: 0.00,
    orderBy: parseInt(productCount + 1, 10) || parseInt(200),
    product_category: categoryId,
    product_images: [], // Initialize with an empty array to store image URLs
    publishedAt: getCurrentDateTimeWithMilliseconds(),
  });


  const selectedMutation =  brochure === '1' ? ADD_PRODUCT : ADD_PRODUCT_2;
  const [addProduct] = useMutation(selectedMutation);

  const s3Client = new S3Client({
    endpoint: S3_ENDPOINT,
    forcePathStyle: true,
    region: 'ams3',
    credentials: {
      accessKeyId: S3_ACCESS_KEY,
      secretAccessKey: S3_SECRET_KEY,
    },
  });

  const uploadImageToS3 = async (file) => {
    if (!file) {
      console.error('No file to upload.');
      return null;
    }

    const uniqueKey = `${uuidv4()}-${file.name}`;

    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: uniqueKey,
      Body: file,
      ACL: 'public-read',
      ContentType: 'image',
      ContentDisposition: 'inline',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const endpoint = `${S3_ENDPOINT}/${S3_BUCKET_NAME}/${uniqueKey}`;
      // console.log(endpoint);
      return endpoint;

    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const [uploadImageMutation] = useMutation(UPLOAD_PRODUCT_IMAGE);

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadImageToS3(file);
      if (imageUrl) {
        try {
          // Call your GraphQL mutation to create a product image here
          const { data, loading, error } = await uploadImageMutation({
            variables: {
              productImageInput: {
                title: 'new image',
                url: imageUrl,
                publishedAt: getCurrentDateTimeWithMilliseconds(),
              },
              fields: ['id', 'title', 'url', 'publishedAt'],
            },
            update: (cache, {data: {createProductImage}}) => {
              cache.modify({
                fields: {
                  productImage(existingProductImages = []) {
                    const newProductImageRef = cache.writeFragment({
                      data: createProductImage,
                      fragment: productImageFragment,
                    });
                    return [...existingProductImages, newProductImageRef];
                  },
                },
              });
            }
          });
  
          if (loading) {
            // Handle loading state
          }
  
          if (error) {
            console.error('Error creating product image:', error);
            // Handle the error state
          }
  
          if (data) {
            // Handle the successful creation of the product image here
            const imageId = data.createProductImage.data.id;
          // Add the image ID to the product_images array
          setNewProduct((prevProduct) => ({
            ...prevProduct,
            product_images: [...prevProduct.product_images, imageId],
          }));
          // console.log(newProduct.product_images, 'product image set after upload');
          // console.log(imageId, 'product image set after upload');
          
            // console.log('new images:', newImage);
             setNewImage((prevImages) => [ ...prevImages, imageUrl]);
          }
        } catch (error) {
          console.error('Error creating product image:', error);
          // Handle the error state
        }
      }
    }
  };

  const handleDeleteImage = (indexToDelete) => {
    const updatedImages = newImage.filter((_, index) => index !== indexToDelete);
    setNewImage(updatedImages);

    setNewProduct((prevProduct) => {
      const updatedImagesProduct = prevProduct.product_images.filter((_, index) => index !== indexToDelete);
      return {
        ...prevProduct,
        product_images: updatedImagesProduct,
      };
    });
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({
      ...newProduct,
      [name]: value,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();

    let cacheUpdated = true;
    try {
      const productCategory = brochure === '1' ? 'product_category' : 'product_2_category';

      const productInputData = {
        productID: newProduct.productID,
        title: newProduct.title,
        description: newProduct.description,
        [productCategory]: categoryId,
        orderBy: newProduct.orderBy,
        price: parseFloat(newProduct.price),
        product_images: newProduct.product_images,
        publishedAt: getCurrentDateTimeWithMilliseconds(),
      };
      // console.log(productInputData, 'product input data');
      // console.log(categoryId, 'category ID')
      const { data, loading, error } = await addProduct({
        variables: {
          productInput: productInputData,
        },
        refetchQueries: [
          {
            query: selectedRefreshQuery,
            variables: { id: categoryId },
          }
        ]
        // update: (cache, { data: { createProduct } }) => {
        //   try {
        //   cache.modify({
        //     fields: {
        //       products(existingProducts = []) {
        //         const newProductRef = cache.writeFragment({
        //           data: createProduct,
        //           fragment: selectedFragment,
        //         });
        //         return [...existingProducts, newProductRef];
        //       },
        //     },
        //   });
        // } catch (cacheError) {
        //   console.error('Error updating the cache:', cacheError);
        //   cacheUpdated = false;
        //   return; // Exit early to avoid executing subsequent code
        // }
        // },
      });
  
      if (loading) {
        return <Loading />;
      }
  
      if (error) {
        console.error('Product not added:', error);
      }
  
      if (data) {

        if(cacheUpdated){
          setTimeout(() => {
            navigate(-1); // Navigate after a delay of 1000ms (1 second)
            // Or navigate to a specific page: navigate(`/products/${previousPage}`);
          }, 1000);
        } else {
          navigate(-1);
          window.location.reload();
          return;
        }
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };
  
  const handleBackButton = () => {
    navigate(-1);
  }

  return (
    <Pages>
    <div
      className='absolute z-10 top-0 left-0 w-full h-full  bg-[#E6E4E0] space-y-20 px-20'
    >
<div className='border-b-[0.5px] border-line p-4 inset-x-0 absolute top-0'>
        <button onClick={handleBackButton} className='flex space-x-2'>
          <img src={backArrow} alt='back arrow' />
          <span>Back to {category}  ({productCount})</span>
        </button>
      </div>
      <h1 className='text-7xl text-[#C5BEB1]'>Add a Product</h1>
      <div className='flex w-full space-x-20'>
        <div className='w-1/3 space-y-6'>
          <h2 className='text-[#C5BEB1] border-b-[0.5px] border-[#C5BEB1]'>
            Product Info
          </h2>
          <div className='w-full flex'>
            <span className='w-1/3'>Product ID</span>
            <input
              name='productID'
              value={newProduct.productID}
              onChange={handleChange}
              className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
              placeholder='Product ID'
              type='textbox'
              required
            />
          </div>
          <div className='w-full flex'>
            <span className='w-1/3'>Product Name</span>
            <input
              name='title'
              value={newProduct.title}
              onChange={handleChange}
              className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
              placeholder='Product Name'
              type='textbox'
            />
          </div>
          <div className='w-full flex'>
            <span className='w-1/3'>Price (£)</span>
            <input
              name='price'
              onInput={(e) => {
                const value = e.target.value;
                const formattedValue = value.replace(/[^\d.]/g, ''); // Remove non-digit characters except dot
                e.target.value = formattedValue;
              }}
              value={newProduct.price}
              onChange={handleChange}
              className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black'
              placeholder='0.00'
              type='textbox'
            />
          </div>
          <div className='w-full flex'>
            <span className='w-1/3'>Product Description</span>
            <textarea
              name='description'
              value={newProduct.description}
              onChange={handleChange}
              className='w-2/3 bg-transparent text-[#C5BEB1] italic focus:outline-none focus:border-b-[0.5px] border-[#C5BEB1] focus:text-black h-80'
              placeholder='Product Description'
              type='textbox'
            />
          </div>
        </div>
        <div className='w-2/3 space-y-6 flex flex-col justify-between'>
          <div>
            <h2 className='text-[#C5BEB1] border-b-[0.5px] border-[#C5BEB1]'>
              Product Photos
            </h2>
          
          <div className="grid grid-cols-4 h-60">
            {newImage && newImage.length > 0 && newImage.map((image, index) => (
              <div key={index} className="relative group">
                <div className="w-[200px] h-[200px] flex justify-center">
                  <img src={image} alt={`Uploaded Product Image ${index}`} className="w-full h-full object-cover" />
                </div>
                <button onClick={() => handleDeleteImage(index)} className=" hidden group-hover:block transition-opacity  opacity-0 absolute bottom-0 z-10 left-0 w-full bg-gray-300 text-white py-1  group-hover:opacity-100">
                  Delete
                </button>
              </div>
            ))}
            <label className='flex bg-[#DCD9D3] text-[#C5BEB1] hover:text-[#DCD9D3] m-auto shadow-md transition duration-500 hover:bg-[#C5BEB1] w-[120px] h-[120px] rounded relative'>
              <span className='m-auto uppercase ] text-2xl '>{newImage && newImage.length === 6 ? 'Full' : '+'}</span>
              <input
                type="file"
                onChange={handleImage}
                className="absolute rounded-md m-auto opacity-0 cursor-pointer"
                accept="image/jpeg, image/png, image/gif, image/webp" //accept only image files with these extensions
                multiple // Allow multiple file selection
                disabled={newImage && newImage.length === 6} // Disable input if newImage has 6 items
              />
            </label>
          </div>
        </div>
        <div className=''>
          <ul className='list-disc list-inside text-xs'>
          <li>Ensure image formats are PNG, JPEG, JPG, or WEBP to prevent compatibility issues.</li>
            <li> Upload images with a max width dimension of 1200px - larger pixel dimensions will cause slower loading speeds.</li>
            <li>Convert to WEBP format for best performance - <a className='underline' target="_blank" rel="noreferrer"  href='https://tiny-img.com/webp/'>TinyIMG Web Converter Tool</a></li>
            <li>Avoid uploading images larger than 1MB.</li>
            <li>The displayed uploaded image adheres to a 1:1 ratio, reflecting how it will appear in the product list.</li>
          </ul>

        </div>


      </div>

      </div>
      <div className='absolute bottom-0 right-0 w-full border-t-[0.5px] border-line'>
        <div className='float-right bg-black px-20 py-10'>
          <button onClick={handleClick} className='text-white uppercase w-auto'>
          {success ? "Saved!" : "Save"}
          </button>
        </div>
      </div>
      <Outlet />
    </div>
    </Pages>
  );
}

import React from 'react'
import { Outlet } from 'react-router-dom'
import Loading from '../components/Loading'
import { useQuery } from '@apollo/client'
import Pages from '../animation/Pages';
import Options from '../components/Options'
import { GET_HOME_DATA } from '../api/GraphQL';
import { GET_HOME_DATA_2 } from '../api/brochure2/GraphQL'
import HomeMenu from '../components/dashboard/HomeMenu'
import { useState } from 'react'




export default function Home() {

    const [homePage, setHomePage] = useState(false);
  const { loading, error, data } = useQuery(GET_HOME_DATA);
  const {loading: b2Loading, error: b2Error, data: brData} = useQuery(GET_HOME_DATA_2)

  if (loading || b2Loading) {
    return <Loading />;
  }

  if (error || b2Error) {
    return <p>Error: {error.message}</p>;
  }


  let { pageHeader, subHeader, button, description, coverImageURL } = homePage ? brData.homeTwo.data.attributes : data.home.data.attributes;



  
const content = {pageHeader, subHeader, coverImageURL, button, description};


const handleBrochureClick = (value) => {
  setHomePage(value);
  // Perform actions based on the brochure button clicked
  // For example, you can update state or trigger other functions here
//   console.log(`Brochure ${value ? '2' : '1'} was clicked`);
};
const type = homePage ? 'brochure2' : 'brochure1';

  return (
    <Pages>
        <div className='w-[100%] h-full! flex overflow-y-auto'>
            <div className='w-[15%] h-full'>
                <HomeMenu onBrochureClick={handleBrochureClick} />
            </div>
            <div className='px-20 pt-20 w-full'>
            <div className='w-full h-auto pr-10 overflow-y-auto scroll-smooth'>
                <h1 className='text-[#C5BEB1] text-6xl font-semibold'>Home</h1>
                <ol className='space-y-8 py-10 w-full'>

                    {/* page header */}
                    <li className=' flex justify-between font-light'>
                        <span className='font-semibold pr-8 w-1/5'>Page Header</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{pageHeader}</div>
                            <Options label='Edit Text' link='edit/pageHeader' content={content} type={type} contentLabel='Page Header' initialDropdownVisible={false} />
                        </div>

    

                    </li>

                    {/* sub header */}
                    <li className=' flex justify-between font-light'>
                        <span className='font-semibold pr-8 w-1/5'>Sub Header</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{subHeader}</div>
                            <Options label='Edit Text' link='edit/subHeader' content={content} type={type}  contentLabel='Sub Header' initialDropdownVisible={false} />
                        </div>

                       

                    </li>

                    {/* cover image  */}
                    <li className=' flex justify-between font-light border-dashed border-b-[0.5px] border-black pb-8'>
                        <span className='font-semibold pr-8 w-1/5'>Cover Image</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>
                                <img src={coverImageURL} alt="example" className="max-h-60 object-fit"/>
                            </div>
                            <Options label='Replace Image' link='edit/coverImageURL' type={type} content={content}  contentLabel='Cover Image' initialDropdownVisible={false} />
                        </div>

                        

                    </li>


                    {/* button text */}
                    <li className=' flex justify-between font-light '>
                        <span className='font-semibold pr-8 w-1/5'>Button Text</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{button}</div>
                            <Options label='Edit Text' link='edit/button' content={content} type={type}  contentLabel='Button Text' initialDropdownVisible={false} />
                        </div>
                    </li>

                    {/* description */}
                    <li className=' flex justify-between font-light '>
                        <span className='font-semibold pr-8 w-1/5'>Description</span>

                        <div className='w-4/5 flex flex-grow justify-between'>
                            <div className='w-3/5 text-sm'>{description}</div>
                            <Options label='Edit Text' link='edit/description' content={content} type={type}  contentLabel='Description' initialDropdownVisible={false} />
                        </div>
                    </li>

                </ol>
            </div>
            <Outlet />
        </div>
        </div>
    </Pages>
  )
}

import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useLocation } from 'react-router-dom';


export default function CategoryNav2({ categories }) {
  const { pathname } = useLocation();

  return (
    <div className="w-full h-full pt-8 pl-2 border-r-[0.5px] border-brown flex flex-col space-y-20">
      <h2 className="font-bold p-2 w-full border-r-2 border-black bg-[#DCD9D3]">Our Products</h2>
      <ul className="list-disc list-inside text-sm pl-0 mx-auto space-y-12 pr-2">
        {categories.map((category) => {
          const encodedTitle = encodeURIComponent(category.attributes.title);
          const categoryPath = `/products-2/${encodedTitle}`;
          const isActive = pathname.startsWith(categoryPath);
          return (
            <li key={category.id} className='hover:underline relative'>
              <Link to={`/products-2/${encodedTitle}`} state={{ categoryId: category.id }}>
                {category.attributes.title}
              </Link>
              {isActive && (
                <motion.span
                  initial={{ width: 0 }}
                  animate={{ width: '100%' }}
                  exit={{ width: 0 }}
                  className='absolute left-0 bottom-[-6px] block h-[0.5px] w-full bg-[#9f9080]'
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

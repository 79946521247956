import React, { useState } from 'react';
import More from '../assets/More.svg';
import { Link, useLocation } from 'react-router-dom';

export default function Options({ initialDropdownVisible, label, link, content, contentLabel, type }) {
  const [isDropdownVisible, setDropdownVisible] = useState(initialDropdownVisible);
// console.log("options content", contentLabel)

  const leaveDropDown = () => {
    // console.log('mouse left')
    setTimeout(() => {
      setDropdownVisible(false);
    }, 2000)
  }
  return (
    <div
      className="relative px-2"
      onClick={() => setDropdownVisible(true)}
      onMouseLeave={leaveDropDown}
    >
      <button className="hover:bg-[#DCD9D3] rounded">
        <img className="h-full" src={More} alt="three vertical dots" />
      </button>

      {isDropdownVisible && (
        <div className="absolute p-2 top-0 right-1/2 transform translate-x-1/2 translate-y-1/3 ">
          <div className='w-full flex justify-center'>
            <svg className='mx-auto' width="12" height="5" viewBox="0 0 12 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.99986 0L11.196 4.5H0.803711L5.99986 0Z" fill="#111011"/>
            </svg>
          </div>
          <div className="w-[120px] bg-black text-xs">
            {Array.isArray(link) && Array.isArray(label)
              ? link.map((item, index) => (
                  <Link
                    key={index}
                    to={item}
                    className="block px-2 py-2 text-white hover:bg-black hover:underline"
                    state={{ content: content, property: contentLabel, type: type }}
                  >
                    {label[index]}
                  </Link>
                ))
              : (
                <Link
                  to={link}
                  className="block px-2 py-2 text-white hover:bg-black hover:underline"
                  state={{ content: content, property: contentLabel, type: type }}
                >
                  {label}
                </Link>
              )
            }
          </div>
        </div>
      )}
    </div>
  );
}

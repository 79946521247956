import { gql } from "@apollo/client"

const UPDATE_HOME_PAGE_2 = gql(`
mutation UpdateHomePage($data: HomeTwoInput!) {
    updateHomeTwo(data: $data) {
      data {
        attributes {
          pageHeader
          subHeader
          button
          description
          coverImageURL
        }
      }
     
    }
  }
`);




const updateHomeImage_2 = gql(`
mutation updateContentItem($id: ID!, $coverImage: ID!) {
    updateContent(id: $id, input: { coverImage: $coverImage }) {
      id
      coverImage {
        id
      }
    }
  }
`);
const UPDATE_CATEGORY_PAGE_DATA_2 = gql`
  mutation UPDATE_CATEGORY_PAGE_DATA_2($data: CategoryTwoInput!) {
    updateCategoryTwo(data: $data) {
      data {
        attributes {
          pageHeader
          paragraph
          coverImageURL
        }
      }
    }
  }
`;

const UPDATE_CATEGORY_DATA_2 = gql`
mutation updateCategory($id: ID!, $data: Product2CategoryInput!){
  updateProduct2Category(id: $id, data: $data){
    data {
      id
      attributes {
        order
        coverImageURL
        iconURL
      }
    }
  }
}
`;

const UPDATE_CATEGORY_ORDER_2 =gql`
mutation updateCatory($id: ID!, $order: Int!){
  updateProduct2Category(id: $id, data: {order: $order}){
    data {
      id
      attributes {
        order
      }
    }
  }
}
`;


const UPLOAD_PRODUCT_IMAGE = gql`
mutation AddProductImage($productImageInput: ProductImageInput!) {
  createProductImage(data: $productImageInput) {
    data {
    id
      attributes {
        url
        title
        publishedAt
      }
    }
  }
}

`;

//add new product

const ADD_PRODUCT_2 = gql`
  mutation AddProduct($productInput: Brochure2ProductInput!) {
    createBrochure2Product(data: $productInput) {
      data{ 
        attributes {
          publishedAt
          productID
          title
          description
          price
          product_2_category {
            data {
              id
            }
          }
          product_images {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

const DELETE_PRODUCT_2 = gql`
mutation DeleteProduct($id: ID!) {
  deleteBrochure2Product(id: $id) {
    data {
      id 
    }
  }
}
`;

const UPDATE_PRODUCT_2 = gql`
mutation UpdateBrochure2Product($id: ID!, $data: Brochure2ProductInput!) {
  updateBrochure2Product(id: $id, data: $data) {
    data {
      id
      attributes {
        productID
        title
        description
        price
        product_2_category {
          data {
            id
          }
        }
        product_images {
          data {
            id
          }
        }
      }
    }
  }
}
`;


const UPDATE_PRODUCT_ORDER_2 = gql`
mutation updateProduct($id: ID!, $orderBy: Int!){
  updateBrochure2Product(id: $id, data: { orderBy: $orderBy }){
    data {
      id
      attributes {
        orderBy
      }
    }
  }
}
`


export {UPDATE_HOME_PAGE_2, DELETE_PRODUCT_2, ADD_PRODUCT_2, UPDATE_PRODUCT_2, UPDATE_CATEGORY_PAGE_DATA_2, UPDATE_CATEGORY_DATA_2, UPDATE_CATEGORY_ORDER_2, UPDATE_PRODUCT_ORDER_2};

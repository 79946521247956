import React from 'react'
import { useLocation } from 'react-router-dom'
import { GET_PRODUCTS_BY_CATEGORY } from '../../../api/GraphQL';
import { GET_PRODUCTS_BY_CATEGORY_2 } from '../../../api/brochure2/GraphQL';
import { UPDATE_PRODUCT_ORDER } from '../../../api/mutations';
import { UPDATE_PRODUCT_ORDER_2 } from '../../../api/brochure2/mutations';
import Loading from '../../Loading';
import { useMutation, useQuery } from '@apollo/client';
import Options from '../../Options';
import DraggableProducts from './DraggableProducts';
import { Link } from 'react-router-dom';
import { client } from '../../../api/Apollo';
export default function Category({brochure, query}) {

  const location = useLocation();
  const { categoryId } = location.state;
  // console.log(categoryId, "cat id")


// console.log(brochure, ": brochure")

  //  const selectedQuery = brochure === '1' ? GET_PRODUCTS_BY_CATEGORY : GET_PRODUCTS_BY_CATEGORY_2;

  const selectedMutation = brochure === '1' ? UPDATE_PRODUCT_ORDER : UPDATE_PRODUCT_ORDER_2;
  const [orderMutation] = useMutation(selectedMutation); 
  const { loading, error, data } = useQuery(query, {
    variables: {
      id: categoryId,
    },
  });
// console.log(data)
  if(loading) {
    return <Loading />
  }
  if(error) {
    return <p> data error</p>
  }
  const { title, coverImageURL, iconURL, order } = brochure === '1' 
  ? data.productCategory.data.attributes 
  : data.product2Category.data.attributes;
const products = brochure === '1' 
  ? data.productCategory.data.attributes.products 
  : data.product2Category.data.attributes.brochure_products;
console.log(products, 'xxx')


const onReorder = async (products) => {
  console.log(products, 'product input')
  
  const updatePromises = products.map((product) =>
    orderMutation({
      variables: {
        id: product.id, // Correctly access the product's ID
        orderBy: product.orderBy, // Correctly access the product's new order
      },
    }).catch(error => {
      console.error(error, 'update failed for product:', product.id, error.message);
      return null; // Return a marker or null to indicate failure
    })
  );

  await Promise.all(updatePromises);

  if(brochure === '1'){
    client.cache.modify({
      fields: {
        product(existingProducts = [], { readField }) {
          // Assuming existingProducts is an array of product references
          const updatedProducts = existingProducts.map((ref) => {
            const refId = readField('id', ref);
            const foundProduct = products.find(p => p.id === refId);
            if (foundProduct) {
              return {
                ...ref,
                orderBy: foundProduct.orderBy, // Update the orderBy value in the cache
              };
            }
            return ref;
          });
          return updatedProducts;
        },
      },
    });

  } else {
    client.cache.modify({
      fields: {
        brochure2Product(existingProducts = [], { readField }) {
          // Assuming existingProducts is an array of product references
          const updatedProducts = existingProducts.map((ref) => {
            const refId = readField('id', ref);
            const foundProduct = products.find(p => p.id === refId);
            if (foundProduct) {
              return {
                ...ref,
                orderBy: foundProduct.orderBy, // Update the orderBy value in the cache
              };
            }
            return ref;
          });
          return updatedProducts;
        },
      },
    });
  }


  console.log('Cache updated with new product order');
};


// console.log(data);
  const content = {title, coverImageURL, iconURL, order, categoryId };
  return (
    <div className='space-y-8 overflow-y-auto h-full'>
      <h1 className='text-5xl text-line font-bold'>{title}</h1>
      <div className='flex w-full justify-between'>
        <h3 className='w-1/5'>Cover Image</h3>
        <img className='w-3/5 max-h-60 object-contain' src={coverImageURL} alt="cover"  />
        <Options className='w-1/5' type='img' initialDropdownVisible={false} label='Replace Image' link='coverImageURL/edit' content={content} contentLabel='Cover Image' />
      </div>
      <div className='relative space-y-4'>
        <h2 className="text-[#A59C8C]">Products ({products.data.length})</h2>
          <div className='absolute top-0 right-0'>
            <Link to={`add`} state={{categoryId, productCount: products.data.length}}  className="uppercase text-xs">
              + Add Products
            </Link>
            </div>
        <DraggableProducts products={products.data} brochure={brochure} categoryId={categoryId} onReorder={onReorder}/>
      </div>
      
      
    </div>
  )
}

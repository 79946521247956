import React from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../../assets/Logo.svg';

import { Link } from 'react-router-dom';

const pageMap = [
  { href: '/home', label: 'Home' },
  { href: '/about', label: 'About' },
  { href: '/products', label: 'Brochure 1' },
  { href: '/products-2', label: 'Brochure 2'}
];

export default function Menu() {
  const {pathname} = useLocation();

  const isActive = (path) => {
    const pathWithSlash = `${path}/`;
    return pathname === path || pathname.startsWith(pathWithSlash);
  };
  

  return (

    <div className='h-screen border-r-[0.5px] border-black w-full flex flex-col'>
      <Link to='/'>
        
        <img src={logo} alt="aqualona logo" className='mx-auto p-2' />
      </Link>
      <nav className='m-auto'>
        <ul className='m-auto space-y-8'>
        {pageMap.map((page, index) => (
        <li key={`${page.href}_${index}`} className='relative p-2'>
      
            <Link to={page.href} className={isActive(page.href) ? "text-[#E6E4E909090]" : "hover:underline"}>
            {isActive(page.href) && (
                <motion.span
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                exit={{ width: 0 }}
                className='absolute left-0 bottom-0 block h-[0.5px] w-full bg-[#9f9080]'
              />
            )}
            <p className='z-10 relative'>{page.label}</p>
            </Link>
        </li>
        ))}

        </ul>
      </nav>

      <div className='justify-between mx-auto pb-8'>
        <li className='list-none rounded-full text-xs bg-black text-light text-center w-10 h-10 flex mx-auto'>
          <a href='https://ior.ad/9ENC?iframeHash=trysteps-1' target='_blank' rel='noopener noreferrer' className='m-auto'>
            FAQ
          </a>
        </li>
        <Link to='/settings'>Settings</Link>
      </div>
    </div>
  );
}
